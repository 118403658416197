import { axios } from '@/utils/request'
const basic = '/manage'
const api = {
  // 查询所有外显号码
  indexShow: basic + '/index/show',
  dataBind: basic + '/index/dataBind',
  updateStatus: basic + '/index/updateStatus'
}
export function indexShow (parameter) {
  return axios({
    url: api.indexShow,
    method: 'post',
    params: parameter
  })
}
export function dataBind (parameter) {
  return axios({
    url: api.dataBind,
    method: 'post',
    params: parameter
  })
}
export function updateStatus (parameter) {
  return axios({
    url: api.updateStatus,
    method: 'post',
    params: parameter
  })
}
