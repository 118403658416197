<template>
  <a-card :bordered="false" class="content">
    <div class="data-box">
      <div>
        <span class="bold">座席状态汇总：</span>
        <span>{{ showData.onlineMembers ? showData.onlineMembers.length : 0 }}人</span>
        <!--        <span>刷新次数</span>-->
        <!--        <a-select default-value="30" style="width: 60px" @change="change" size="small" v-model="time">-->
        <!--          <a-select-option :value="1">-->
        <!--            1-->
        <!--          </a-select-option>-->
        <!--          <a-select-option :value="10">-->
        <!--            10-->
        <!--          </a-select-option>-->
        <!--          <a-select-option :value="30">-->
        <!--            30-->
        <!--          </a-select-option>-->
        <!--          <a-select-option :value="60">-->
        <!--            60-->
        <!--          </a-select-option>-->
        <!--        </a-select>-->
        <!--        <span>秒</span>-->
      </div>
      <div class="preview-box">
        <!--        <div>-->
        <!--          <p class="title">在线数</p>-->
        <!--          <div class="num-box">{{ showData.onlineCount }}</div>-->
        <!--        </div>-->
        <div>
          <p class="title">通话数</p>
          <div class="num-box">{{ showData.callCount }}</div>
        </div>
        <div>
          <p class="title">空闲数</p>
          <div class="num-box">{{ showData.idleCount }}</div>
        </div>
        <div>
          <p class="title">示忙数</p>
          <div class="num-box">{{ showData.busyCount }}</div>
        </div>
        <div>
          <p class="title">事务处理</p>
          <div class="num-box">{{ showData.transactionCount }}</div>
        </div>
        <div>
          <p class="title">排队数</p>
          <div class="num-box">{{ showData.lineCount }}</div>
        </div>
      </div>
    </div>
    <div class="user-content">
      <div class="user-box" v-for="(data, key) of showData.onlineMembers" :key="key">
        <div class="img-box">
          <p class="p1">{{ data.fsAccount }}-{{ data.orgName }}</p>
          <img src="../../assets/user.png" alt="图片加载失败" />
          <p class="p2">{{ data.realName }}</p>
        </div>
        <p>{{ data.status | status }}({{ data.updateTime | time }}s)</p>
      </div>
    </div>
  </a-card>
</template>

<script>
import moment from 'moment'
import { indexShow } from '@/api/home'

export default {
  name: 'AdminHome',
  data() {
    return {
      showData: {},
      time: 1,
      setTime: null
    }
  },
  filters: {
    status(value) {
      switch (value) {
        case 'ONLINE':
          return '在线'
        case 'CALL':
          return '通话中'
        case 'IDLE':
          return '空闲'
        case 'BUSY':
          return '置忙'
        case 'TRANSACTION':
          return '事务处理中'
        case 'LINE':
          return '排队中'
        case 'OFF_LINE':
          return '离线'
      }
    },
    time(value) {
      const date1 = new Date(value)
      const date2 = new Date()
      const s1 = date1.getTime()
      const s2 = date2.getTime()
      return parseInt((s2 - s1) / 1000)
    }
  },
  created() {
    this.indexShow()
    this.setTimeVal()
  },
  beforeDestroy() {
    clearInterval(this.setTime)
  },
  methods: {
    moment,
    setTimeVal() {
      this.setTime = setInterval(() => {
        this.indexShow()
      }, 1000)
    },
    change() {
      clearInterval(this.setTime)
      this.setTimeVal()
    },
    indexShow() {
      indexShow()
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          res.body.onlineMembers.sort((a, b) => {
            if (a.orgName !== b.orgName) {
              return b.orgName.localeCompare(a.orgName, 'zh-CN')
            }
            return b.updateTime - a.updateTime
          })
          this.showData = res.body
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
    }
  }
}
</script>

<style scoped>
/* .content {
  height: 100%;
} */
.data-box {
  box-sizing: border-box;
  padding: 40px;
  border: 1px solid #f9f0ff;
}
.preview-box {
  margin-top: 40px;
  display: flex;
  text-align: center;
}
.preview-box > div {
  width: 60px;
  margin-right: 60px;
}
.num-box {
  border: 1px solid #f9f0ff;
}
.bold {
  font-weight: bold;
}
.user-content {
  /* margin-top: 40px; */
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.user-box {
  width: 100px;
  height: 100px;
  margin: 20px;
  padding-top: 20px;
}
.img-box {
  height: 70px;
  border: 1px solid #bfbfbf;
  position: relative;
}
.img-box img {
  width: 70px;
  height: 70px;
}
.img-box > .p1 {
  position: absolute;
  top: -20px;
  margin: 0;
  left: 50%;
  white-space: nowrap;
  transform: translate(-50%, 0);
}
.img-box > .p2 {
  position: absolute;
  bottom: 2px;
  margin: 0;
  left: 50%;
  white-space: nowrap;
  transform: translate(-50%, 0);
  font-weight: bold;
}
</style>
